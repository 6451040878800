.task {
  /* width: 60%; */
  background-color: #19181c;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  padding: 20px 30px;
  border-radius: 25px;
  color: #fff;
  height: 563px;
  position: relative;
  overflow-y: hidden;
}
h4 {
  font-size: 20px;
  color: #8e91d6;
  margin-bottom: 5px;
}
.task_content {
  position: relative;
}

.task_content span {
  color: #fff;
  font-size: 16px;
  overflow-y: hidden;
  overflow-y: scroll;
}
.cicle_task {
  display: flex;
  align-items: center;
  background-color: #312c37;
  gap: 15px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.cicle_task:hover {
  background-color: #4b4353;
}
.userInput {
  display: flex;
  align-items: center;
  gap: 12px;
}
.circle_icon {
  font-size: 20px;
  color: #edededaa;
}

.start_icon {
  color: #edededaa;
  position: absolute;
  right: 5%;
}

.btn {
  font-size: 20px;
  color: #fff;
  border: none;
  position: absolute;
  bottom: 5%;
  right: 5%;
  background: #744df7;
  padding: 10px 25px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.1s ease;
  cursor: pointer;
}
/* after background turn white */
.task2-w {
  background-color: #e5eef8;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.active h4 {
  color: #ff8283;
}
.task_content-w span {
  color: #9b9fb2;
}
/* .task_content-w:hover {
  background-color: #d3dfe2;
} */
.circle_icon-w {
  color: #7b8ba3;
}
.start_icon-w {
  color: #7b8ba3;
}
.btn-w {
  background-color: #f8877a;
  color: black;
}
.task_content p {
  text-align: center;
  font-size: 18px;
}
.cicle_task-bgw {
  background-color: #d3dfe2;
}
.cicle_task-bgw:hover {
  background-color: #d3dfe266;
}
