.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 20px;
  margin-top: 5px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a5afbf;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:hover {
  background-color: #ff8283;
}
.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #a0a0a04d;
}
input:checked + .slider:hover {
  background-color: #744df7;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* after background turn white */

.switch-ON:hover {
  background-color: black;
}
